import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputRightElement,
  ChakraProvider,
} from "@chakra-ui/react";

const Login: React.FC<{
  phoneNumber: string;
  handleSuccessfulAuth: () => void;
}> = ({ phoneNumber, handleSuccessfulAuth }) => {
  const [show, setShow] = React.useState(false);
  const [phoneValue, setPhoneValue] = React.useState("");

  const validatePhoneNumber = () => {
    if (phoneValue === phoneNumber) {
      handleSuccessfulAuth();
    } else {
      alert("מספר טלפון שגוי");
    }
  };

  return (
    <>
      <ChakraProvider>
        <Modal
          onClose={() => {}}
          isCentered
          isOpen={true}
          motionPreset="slideInBottom"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader textAlign="center">
              יש להזין מספר טלפון כדי לצפות במסמך
            </ModalHeader>
            <ModalBody>
              <InputGroup size="md">
                <Input
                  type="text"
                  placeholder="מספר טלפון"
                  value={phoneValue}
                  onChange={(e) => setPhoneValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      validatePhoneNumber();
                    }
                  }}
                />
              </InputGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="solid"
                colorScheme="pink"
                onClick={validatePhoneNumber}
              >
                התחברות
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </ChakraProvider>
    </>
  );
};

export default Login;
