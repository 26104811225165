export interface ISection {
  name: string;
  entries: string[];
  cuanto?: string[];
  subEntries?: string[];
}

const Section = (section: ISection) => {
  const sectionName = section.name;
  const isGeneralSection = sectionName.includes("הערות כלליות");
  const subEntries = section.subEntries;

  return (
    <div
      className={`no-page-break section ${
        isGeneralSection && "general-section"
      }`}
      data-section-name={section.name}
    >
      {!isGeneralSection && <h4 className="section-title">{section.name}</h4>}
      <ul className={section.name === "איטום מרתף- הערה" ? 'general-comment section-text' : 'section-text'}>
        {section.entries?.map((entry, index) => {
          return <li key={index}>{entry}</li>;
        })}
      </ul>
      {subEntries && (
        <div className="section general-section">
          <ul className="section-text">
            {subEntries.map((subEntry) => (
              <li>{subEntry}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Section;
