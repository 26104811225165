import * as XLSX from "xlsx";
import Section, { ISection } from "./Section";
import {
  getChapterProperties,
  getColumnCountFromCsv,
  transformSheetPrices,
} from "../assets/utils";
import { ECOMPANY } from "../constants";
import { useContext, useEffect, useRef } from "react";
import { Context } from "../App";

export interface IChapter {
  title?: string;
  name: string;
  sections: ISection[];
  dataSheet?: XLSX.WorkSheet;
}

const AttacheFinancial = (chapter: IChapter) => {
  //const ref  = useRef<HTMLDivElement>(null);
  // @ts-ignore

  // console.log({ chapter });

  const [company] = useContext(Context);
  const isCuanto: boolean = company === ECOMPANY.CUANTO;
  const modifySrc = isCuanto && "_c";
  const chapterProperties = getChapterProperties(chapter?.name);

  const tableColumnCount =
    chapter?.dataSheet &&
    getColumnCountFromCsv(XLSX.utils.sheet_to_csv(chapter.dataSheet, {}));

  const html =
    chapter?.dataSheet &&
    XLSX.utils.sheet_to_html(
      XLSX.utils.json_to_sheet(XLSX.utils.sheet_to_json(chapter.dataSheet))
    );

  const tableRef = useRef<HTMLDivElement>(null);
  const newTablesRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!tableRef.current) return;
    // console.log(tableRef);
    tableRef?.current
      ?.querySelector("table > tbody > tr:first-child")
      ?.remove();
    const table = tableRef?.current?.querySelector("table");
    const rows: any = table?.rows;
    let lastProgramCell: any = null;
    let rowspan = 1;
    let even = 0;

    for (let i = 1; i < rows.length; i++) {
      // start from 1 to skip the header row
      const currentCell = rows[i].cells[0];

      if (currentCell.textContent.trim() === "") {
        rowspan++;
        currentCell.style.display = "none"; // hide the empty cell
        currentCell.classList.add("hidden");
      } else {
        if (lastProgramCell) {
          lastProgramCell.rowSpan = rowspan;
          lastProgramCell.closest("tr").classList.add("group");
        }
        lastProgramCell = currentCell;
        rowspan = 1;
        if (even % 2 === 0) {
          (lastProgramCell as HTMLTableCellElement).classList.add("even");
        } else {
          (lastProgramCell as HTMLTableCellElement).classList.add("odd");
        }
        even++;
      }
    }

    // Apply the rowspan to the last program cell
    if (lastProgramCell) {
      lastProgramCell.rowSpan = rowspan;
    }

    table?.querySelectorAll("tr").forEach((tr) => {
      const last = tr.querySelector("td:nth-child(4)");
      if (last) {
        last.remove();
      }
    });

    table?.querySelectorAll(".even, .odd").forEach((td) => {
      const next = td.nextSibling?.nextSibling;
      if (next) {
        (next as HTMLTableCellElement).rowSpan = (
          td as HTMLTableCellElement
        ).rowSpan;

        console.log({ next: next.textContent });

        if (next.textContent?.trim() === "התקבל") {
          console.log("next test", next.textContent);
          (
            next as HTMLTableCellElement
          ).innerHTML = `<img alt="" class="attache-icon" data-name="התקבל" src="${
            process.env.PUBLIC_URL
          }/icons/plans_v${modifySrc ? modifySrc : ""}.svg"/>`;
        }
        if (next.textContent?.trim() === "לא התקבל") {
          console.log("next test2", next.textContent);
          (
            next as HTMLTableCellElement
          ).innerHTML = `<img alt="" class="attache-icon" data-name="לא התקבל" src="${
            process.env.PUBLIC_URL
          }/icons/plans_x${modifySrc ? modifySrc : ""}.svg"/>`;
        }
        if (
          next.textContent === "התקבל חלקית" ||
          next.textContent === "חלקי" ||
          next.textContent === "חלקי משתמש"
        ) {
          console.log("next test3", next.textContent);
          (
            next as HTMLTableCellElement
          ).innerHTML = `<img alt="" class="attache-icon" data-name="התקבל חלקית" src="${
            process.env.PUBLIC_URL
          }/icons/plans_vx${modifySrc ? modifySrc : ""}.svg"/>`;
        }
      }
    });

    table?.querySelectorAll(".hidden").forEach((td) => {
      const next = td.nextSibling?.nextSibling;
      (next as HTMLTableCellElement).style.display = "none";
    });

    // Cut tabe in two
    const splitIndex = 25;

    const createNewTable = () => {
      const table = document.createElement("table");
      const tbody = document.createElement("tbody");
      const thead = document.createElement("thead");

      const headerRow = document.createElement("tr");
      const headers = ["שם התוכנית", "תכולה", "התקבל"];
      headers.forEach((headerText) => {
        const th = document.createElement("th");
        th.textContent = headerText;
        headerRow.appendChild(th);
      });

      thead.appendChild(headerRow);
      table.appendChild(thead);
      table.classList.add("plan-table");

      table.appendChild(tbody);
      return table;
    };

    const newTable1 = createNewTable();
    const newTable2 = createNewTable();

    newTablesRef.current?.appendChild(newTable1);
    newTablesRef.current?.appendChild(newTable2);

    const newRows = Array.from(table!.rows);

    newRows.forEach((row, index) => {
      const newRow = row.cloneNode(true);
      if (index < splitIndex) {
        newTable1!.querySelector("tbody")?.appendChild(newRow);
      } else {
        newTable2!.querySelector("tbody")?.appendChild(newRow);
      }
    });

    newTable1!.querySelector("tbody tr:first-child")?.remove();
  }, [tableRef, newTablesRef]);

  return (
    <>
      <div key={chapter.name} data-name={chapter?.name} className={`chapter`}>
        <img
          className="logo main"
          src={
            process.env.PUBLIC_URL +
            `/icons/${company === ECOMPANY.CUANTO ? "cuanto_logo" : "logo"}.svg`
          }
          alt=""
        />
        <h2 className={"chapter-title"}>
          {" "}
          {chapterProperties && chapterProperties.icon && (
            <img alt="" src={process.env.PUBLIC_URL + chapterProperties.icon} />
          )}
          <span>
            {chapterProperties?.altName
              ? chapterProperties.altName
              : chapter.name}
          </span>
        </h2>
        {chapter.sections
          .filter((section) => section.name !== "כותרת על")
          .map((section) => Section(section))}
        <div className="chapter-end-filler no-page-break chapter-image">
          {chapterProperties && chapterProperties.img && (
            <img alt="" src={process.env.PUBLIC_URL + chapterProperties?.img} />
          )}
        </div>
        <div className="footer">
          <img
            src={
              process.env.PUBLIC_URL +
              `/images/${
                company === ECOMPANY.CUANTO ? "footer_cuanto" : "footer"
              }.jpg`
            }
            alt=""
          />
        </div>
      </div>
      <div
        style={{
          display: tableRef.current ? "block" : "none",
        }}
        key={chapter.name}
        data-name={chapter?.name}
        className={`chapter ${
          chapterProperties?.notFullPage ? "notFullPage" : ""
        }`}
      >
        <img
          className="logo main"
          src={
            process.env.PUBLIC_URL +
            `/icons/${company === ECOMPANY.CUANTO ? "cuanto_logo" : "logo"}.svg`
          }
          alt=""
        />
        <h2 className={"chapter-title"}>
          <span style={{ fontSize: "16px" }}>
            טבלת תוכניות
            {/** chapterProperties?.altName
              ? chapterProperties.altName
              : chapter.name **/}
          </span>
        </h2>
        {chapter.dataSheet && (
          <div
            ref={tableRef}
            style={{ display: "none" }}
            className={`no-page-break table-attache table-col-count-${tableColumnCount}`}
            dangerouslySetInnerHTML={{ __html: html! }}
          />
        )}
        <div ref={newTablesRef} className="new-attache-tables"></div>
        {/* <div className="footer">
          <img
            src={
              process.env.PUBLIC_URL +
              `/images/${
                company === ECOMPANY.CUANTO ? "footer_cuanto" : "footer"
              }.jpg`
            }
            alt=""
          />
        </div> */}
      </div>
    </>
  );
};

export default AttacheFinancial;
