import * as XLSX from "xlsx";
import Section, { ISection } from "./Section";
import { getChapterProperties } from "../assets/utils";
import { ECOMPANY } from "../constants";
import { useContext } from "react";
import { Context } from "../App";
import { IChapter } from "./Chapter";
import TotalSection from "./TotalSection";

const Doors = (chapter: IChapter) => {
  //const ref  = useRef<HTMLDivElement>(null);
  // @ts-ignore

  const [company] = useContext(Context);
  let mutatedSections = [...chapter.sections];
  const innerDoorRemarks = mutatedSections.find(
    (s) => s.name === "הערות כלליות 1"
  )?.entries;
  const innerDoor = mutatedSections.find((s) => s.name === "דלתות פנים");
  if (innerDoor) {
    innerDoor.subEntries = [];
  }
  const outerDoorRemarks = mutatedSections.find(
    (s) => s.name === "הערות כלליות 2"
  )?.entries;
  const outerDoor = mutatedSections.find(
    (s) => s.name === "דלת חוץ" || s.name === "דלתות חוץ"
  );
  if (outerDoor) {
    outerDoor.subEntries = [];
  }
  innerDoorRemarks && innerDoor?.subEntries?.push(...innerDoorRemarks);
  outerDoorRemarks && outerDoor?.subEntries?.push(...outerDoorRemarks);

  const chapterProperties = getChapterProperties(chapter?.name);

  return (
    <div
      key={chapter.name}
      data-name={chapter?.name}
      data-group={chapter.group}
      className={`chapter ${
        chapterProperties?.notFullPage ? "notFullPage" : ""
      }`}
    >
      <h2 className={"chapter-title"}>
        {" "}
        {chapterProperties && chapterProperties.icon && (
          <img alt="" src={process.env.PUBLIC_URL + chapterProperties.icon} />
        )}
        <span>
          {chapterProperties?.altName
            ? chapterProperties.altName
            : chapter.name}
        </span>
      </h2>
      {mutatedSections
        .filter(
          (s) => s.name !== "הערות כלליות 1" && s.name !== "הערות כלליות 2"
        )
        .map((section) => {
          return Section(section);
        })}
      {chapter.dataSheet && (
        <div
          className={`no-page-break ${
            (chapterProperties && chapterProperties.tableClass) || "table1"
          }`}
          dangerouslySetInnerHTML={{
            __html: XLSX.utils.sheet_to_html(chapter.dataSheet, {
              editable: true,
            }),
          }}
        />
      )}
      <div className="before-total-section hidden"></div>
      {chapter.total && chapter.totalOfTotals ? (
        <TotalSection
          name={chapter.name}
          total={chapter.total}
          totalOfTotals={chapter.totalOfTotals}
          averages={chapter.averages}
          bars={chapter.bars}
        />
      ) : null}
      <div className="chapter-end-filler no-page-break chapter-image">
        {chapterProperties && chapterProperties.img && (
          <img alt="" src={process.env.PUBLIC_URL + chapterProperties?.img} />
        )}
      </div>
      <div className="footer">
        <img
          src={
            process.env.PUBLIC_URL +
            `/images/${
              company === ECOMPANY.CUANTO ? "footer_cuanto" : "footer"
            }.jpg`
          }
          alt=""
        />
      </div>
    </div>
  );
};

export default Doors;
