import React from "react";
import { normalDistColors } from "./NormalDistChart";

const TotalSectionBar = ({
  index,
  height,
}: {
  index: number;
  height: number;
}) => {
  return (
    <div
      style={{
        width: "16px",
        height: `${height > 100 ? 100 : height}%`,
        backgroundColor: normalDistColors[index],
        transform: `translateX(${index * -4}px)`,
      }}
    ></div>
  );
};

export default TotalSectionBar;
