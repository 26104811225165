import { useContext } from "react";
import { ECOMPANY } from "../constants";
import { ISection } from "./Section";
import { Context } from "../App";
import { isMobile } from "react-device-detect";

export interface ICover {
  name: string;
  sections: ISection[];
}

const Cover = (coverDetails: ICover) => {
  const familyName = findFamilyName(coverDetails.sections[0].entries);
  const [company] = useContext(Context);
  const isCuanto = company === ECOMPANY.CUANTO;

  console.log({ company });

  function findFamilyName(entries) {
    // console.log({ entries });
    let result = "";
    entries.map((entry) => {
      if (entry.includes("שם הלקוח")) {
        result = entry.replace("שם הלקוח", "").trim();
      }
    });
    return result;
  }

  if (isMobile) {
    return null;
  }

  return (
    <div className="cover">
      <img
        className="logo-cover"
        src={
          process.env.PUBLIC_URL +
          `/icons/${isCuanto ? "cuanto_logo" : "logo"}.svg`
        }
        alt=""
      />
      <div className={`cover-overlay${isCuanto ? " cuanto" : ""}`}>
        <div
          className={isCuanto ? "customer-details-blue" : "customer-details"}
        >
          {coverDetails.sections[0].entries.map((customerDeatil) => (
            <div className="detail">
              {customerDeatil.replace("שם הלקוח", "שם הלקוח: ")}
            </div>
          ))}
        </div>
        <div
          className={isCuanto ? "cover-title !mx-auto mt-14" : "cover-title"}
          contentEditable
        >
          {isCuanto ? (
            <div className="font-alef">אומדן מחיר לפרויקט בניה</div>
          ) : (
            "הצעת מחיר לפי מפרט עבודה"
          )}
          {isCuanto && (
            <img
              className="mx-auto -mt-1 w-[83%] h-[1px]"
              src={process.env.PUBLIC_URL + "/utils/seperator.png"}
              alt=""
            />
          )}
          <div className={isCuanto ? "family-name" : ""}>{familyName}</div>
          {isCuanto && (
            <div
              style={{ lineHeight: "14px", fontSize: "14px" }}
              className="customer-details-blue"
            >
              <div>האומדן נבנה בהתאמה אישית</div>
              <div>על סמך התוכניות שהוגשו ומילוי שאלון.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cover;
