import { Box } from "@mui/material";
import ReactECharts from "echarts-for-react";

interface NormalDistChartProps {
  points: number[];
}

export const normalDistColors: string[] = [
  "rgba(96, 127, 174, 1)",
  "rgba(146, 165, 193, 1)",
  "rgba(105, 147, 205, 1)",
  "rgba(95, 169, 149, 1)",
  "rgba(219, 174, 159, 1)",
];

const NormalDistChart = ({ points }: NormalDistChartProps) => {
  points = points.map((current) => (current >= 100 ? 98 : current));

  // Function to generate normal distribution data
  function normalDistribution(mean, stdDev, points) {
    let data: any = [];
    let step = (stdDev * 6) / (points - 1); // covers 6 standard deviations (-3σ to +3σ)
    let xValues: any = [];

    for (let i = 0; i < points; i++) {
      let x = mean - 3 * stdDev + i * step;
      xValues.push(x); // Avoid using toFixed here to keep precision

      let y =
        (1 / (stdDev * Math.sqrt(2 * Math.PI))) *
        Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2));
      data.push(y); // Keep precision for y-values
    }

    return { xValues, data };
  }

  const mean = 0;
  const stdDev = 1;
  const { xValues, data } = normalDistribution(mean, stdDev, 100);

  const leftLineIndex = xValues.findIndex(
    (x) => Math.abs(x - (mean - stdDev)) < 0.01
  );
  const rightLineIndex = xValues.findIndex(
    (x) => Math.abs(x - (mean + stdDev)) < 0.01
  );

  const leftLineY = data[leftLineIndex];
  const rightLineY = data[rightLineIndex];

  const meanIndex = xValues.findIndex((x: number) => Math.abs(x - mean) < 0.01);
  const meanY = data[meanIndex];

  const arrowOffset = 0.1;
  const adjustedMeanY = meanY + arrowOffset;

  const option = {
    xAxis: {
      type: "category",
      offset: 5,
      axisLabel: {
        show: false,
      },
      axisLine: {
        // show: false,
        lineStyle: {
          color: "#89C1EA",
        },
        onZero: false,
      },
      axisTick: {
        show: false,
      },
      data: xValues.map((x: number) => x.toFixed(2)), // Use toFixed here for display purposes
    },
    yAxis: {
      max: 1.5,
      type: "value",
      show: false,
    },
    series: [
      {
        data: data,
        type: "line",
        smooth: true,
        showSymbol: false,
        lineStyle: {
          color: "#6993CD",
        },
        z: 1,
      },
      {
        type: "line",
        data: [
          [(xValues[leftLineIndex] as number).toFixed(2), 0],
          [(xValues[leftLineIndex] as number).toFixed(2), leftLineY],
        ],
        showSymbol: false,
        lineStyle: {
          color: "#89C1EA",
          type: "dashed",
        },
        z: 1,
      },
      {
        type: "line",
        data: [
          [(xValues[rightLineIndex] as number).toFixed(2), 0],
          [(xValues[rightLineIndex] as number).toFixed(2), rightLineY],
        ],
        showSymbol: false,
        lineStyle: {
          color: "#89C1EA",
          type: "dashed",
        },
        z: 1,
      },
      ...points.map((point, index) => ({
        type: "scatter",
        data: [[(xValues[point] as number)?.toFixed(2), data[point]]],
        symbolSize: 10,
        itemStyle: {
          color: normalDistColors[index],
          opacity: 1,
        },
        z: 2,
      })),
    ],
  };

  return (
    <Box position="relative">
      <span
        style={{
          position: "absolute",
          bottom: "150px",
          right: "-70px",
          transform: "translate(-50%, -50%)",
          fontSize: "12px",
          color: "rgb(236, 0, 140)",
          fontWeight: "bold",
        }}
      >
        מיקומך ביחס לממוצע
      </span>
      <span
        style={{
          position: "absolute",
          bottom: "130px",
          right: "-38px",
          transform: "translate(-50%, -50%)",
          fontSize: "10px",
          color: "rgba(96, 127, 174, 1)",
          fontWeight: "bold",
        }}
      >
        מחיר למ״ר
      </span>
      <span
        style={{
          position: "absolute",
          bottom: "36px",
          right: "-10px",
          transform: "translate(-50%, -50%)",
          fontSize: "11px",
          color: "rgba(96, 127, 174, 1)",
          fontWeight: "bold",
        }}
      >
        גבוה
      </span>
      <span
        style={{
          position: "absolute",
          bottom: "36px",
          right: "170px",
          transform: "translate(-50%, -50%)",
          fontSize: "11px",
          color: "rgba(96, 127, 174, 1)",
          fontWeight: "bold",
        }}
      >
        נמוך
      </span>
      <Box
        sx={{
          marginRight: "-2em",
        }}
      >
        <ReactECharts
          option={option}
          style={{ height: "300px", width: "260px" }}
        />
      </Box>
    </Box>
  );
};

export default NormalDistChart;
