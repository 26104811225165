import { useState, useEffect } from "react";

const LongTableItem = ({
  tableData,
  title,
}: {
  tableData: {title: string, values: Array<Array<string>>};
  title: string;
}) => {

    const [longestRow, setLongestRow] = useState(0);

    useEffect(() => {
        tableData.values.forEach((tr) => {
            if (tr.length > longestRow) {
                setLongestRow(tr.length);
            }
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData])

  return (
    <table>
      <tr>
          <th colSpan={longestRow}>{title}</th>
      </tr>
      {tableData.values.map((tr, i) => {
        // Delete entries where no price is present
        if (!tr[2]) return false;
        return (
          <tr key={i}>
            {tr.map((td, i) => {
              return <td key={i}>{td}</td>;
            })}
          </tr>
        );
      })}
    </table>
  );
};

export default LongTableItem;
