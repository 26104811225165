import * as XLSX from "xlsx";
import Section, { ISection } from "./Section";
import {
  getChapterProperties,
  getColumnCountFromCsv,
  transformSheetPrices,
} from "../assets/utils";
import { ECOMPANY } from "../constants";
import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../App";
import { IChapter } from "./Chapter";
import TotalSection from "./TotalSection";

const ALUMINUM_SPLIT_THRESHOLD = 20;

const Aluminum = (chapter: IChapter) => {
  //const ref  = useRef<HTMLDivElement>(null);
  // @ts-ignore

  const [company] = useContext(Context);
  const chapterProperties = getChapterProperties(chapter?.name);
  const tableRef = useRef<HTMLDivElement>(null);
  const tableRefSplit = useRef<HTMLDivElement>(null);
  const [isSplit, setIsSplit] = useState(false);
  const [canFitPicture, setCanFitPicture] = useState(false);

  const tableColumnCount =
    chapter.dataSheet &&
    getColumnCountFromCsv(XLSX.utils.sheet_to_csv(chapter.dataSheet, {}));

  useEffect(() => {
    if ((tableRef.current as HTMLDivElement)?.querySelector("table")) {
      const table = (tableRef.current as HTMLDivElement).querySelector("table");
      if (table?.rows.length! <= 14) {
        setCanFitPicture(true);
      }
      if (table?.rows.length! > ALUMINUM_SPLIT_THRESHOLD) {
        setIsSplit(true);
        const rows = table?.getElementsByTagName("tr");
        if (rows) {
          for (var i = rows.length; i > ALUMINUM_SPLIT_THRESHOLD - 1; i--) {
            if (rows[i]) {
              rows[i].classList.add("hidden");
            }
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if ((tableRefSplit.current as HTMLDivElement)?.querySelector("table")) {
      const tableSplit = (
        tableRefSplit.current as HTMLDivElement
      ).querySelector("table");
      const rows = tableSplit?.querySelectorAll("tr:not(:first-child)");
      if (rows) {
        for (var i = 0; i < ALUMINUM_SPLIT_THRESHOLD - 1; i++) {
          if (rows[i]) {
            rows[i].classList.add("hidden");
          }
        }
      }
    }
  }, []);

  return (
    <>
      <div
        key={chapter.name}
        data-name={chapter?.name}
        data-group={chapter?.group}
        className={`chapter ${
          chapterProperties?.notFullPage ? "notFullPage" : ""
        }`}
      >
        <img
          className="logo main"
          src={
            process.env.PUBLIC_URL +
            `/icons/${company === ECOMPANY.CUANTO ? "cuanto_logo" : "logo"}.svg`
          }
          alt=""
        />
        {chapter.sections
          .filter((section) => section.name === "כותרת על")
          .map((section) => (
            <h3 className="pre-title">{section.entries[0]}</h3>
          ))}
        <h2 className={"chapter-title"}>
          {" "}
          {chapterProperties && chapterProperties.icon && (
            <img alt="" src={process.env.PUBLIC_URL + chapterProperties.icon} />
          )}
          <span>
            {chapterProperties?.altName
              ? chapterProperties.altName
              : chapter.name}
          </span>
        </h2>
        {chapter.sections
          .filter((section) => section.name !== "כותרת על")
          .map((section) => Section(section))}
        {chapter.dataSheet && (
          <div
            ref={tableRef}
            className={`no-page-break ${
              (chapterProperties && chapterProperties.tableClass) || "table1"
            } table-col-count-${tableColumnCount}`}
            dangerouslySetInnerHTML={{
              __html: transformSheetPrices(
                XLSX.utils.sheet_to_html(chapter.dataSheet, { editable: true }),
                { removeDecimals: chapterProperties?.noDecimals }
              ),
            }}
          />
        )}
        <div className="before-total-section hidden"></div>
        {chapter.total && chapter.totalOfTotals ? (
          <TotalSection
            name={chapter.name}
            total={chapter.total}
            totalOfTotals={chapter.totalOfTotals}
            averages={chapter.averages}
            bars={chapter.bars}
          />
        ) : null}
        <div
          style={{ display: canFitPicture ? "flex" : "none" }}
          className="chapter-end-filler no-page-break chapter-image"
        >
          {chapterProperties && chapterProperties.img && (
            <img alt="" src={process.env.PUBLIC_URL + chapterProperties?.img} />
          )}
        </div>
        <div className="footer">
          <img
            src={
              process.env.PUBLIC_URL +
              `/images/${
                company === ECOMPANY.CUANTO ? "footer_cuanto" : "footer"
              }.jpg`
            }
            alt=""
          />
        </div>
      </div>
      <div
        style={{ display: isSplit ? "flex" : "none" }}
        key={chapter.name}
        data-name={chapter?.name}
        className={`chapter ${
          chapterProperties?.notFullPage ? "notFullPage" : ""
        }`}
      >
        <img
          className="logo main"
          src={
            process.env.PUBLIC_URL +
            `/icons/${company === ECOMPANY.CUANTO ? "cuanto_logo" : "logo"}.svg`
          }
          alt=""
        />
        {chapter.sections
          .filter((section) => section.name === "כותרת על")
          .map((section) => (
            <h3 className="pre-title">{section.entries[0]}</h3>
          ))}
        {/* <h2  className={"chapter-title"}>
          {" "}
          {chapterProperties && chapterProperties.icon && (
            <img alt="" src={process.env.PUBLIC_URL + chapterProperties.icon} />
          )}
          <span>
            {chapterProperties?.altName
              ? chapterProperties.altName
              : chapter.name}
          </span>
        </h2> */}
        {/* {chapter.sections.filter((section) => section.name !== "כותרת על").map((section) => Section(section))} */}
        {chapter.dataSheet && (
          <div
            style={{
              paddingTop: "5.5em",
            }}
            ref={tableRefSplit}
            className={`no-page-break ${
              (chapterProperties && chapterProperties.tableClass) || "table1"
            } table-col-count-${tableColumnCount}`}
            dangerouslySetInnerHTML={{
              __html: transformSheetPrices(
                XLSX.utils.sheet_to_html(chapter.dataSheet, { editable: true }),
                { removeDecimals: chapterProperties?.noDecimals }
              ),
            }}
          />
        )}
        <div className="chapter-end-filler no-page-break chapter-image">
          {chapterProperties && chapterProperties.img && (
            <img alt="" src={process.env.PUBLIC_URL + chapterProperties?.img} />
          )}
        </div>
        <div className="footer">
          <img
            src={
              process.env.PUBLIC_URL +
              `/images/${
                company === ECOMPANY.CUANTO ? "footer_cuanto" : "footer"
              }.jpg`
            }
            alt=""
          />
        </div>
      </div>
    </>
  );
};

export default Aluminum;
