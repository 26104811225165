import React from 'react'

const Error = ({ error, resetErrorBoundary }) =>  {  
    return (
        <div className="bg-black/90 flex w-full h-full absolute justify-center items-center z-50 left-0">
          <span className="text-white text-5xl text-center">
            אוף, משהו השתבש
            <br />
            <hr style={{marginTop: "1em"}} />
            <span className='text-3xl'>
            {error.message}
            </span>
            </span>
        </div>
    );
  }
  

export default Error