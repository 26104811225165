import React, { useContext } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  ChakraProvider,
  FormLabel,
  Text,
  Stack,
  InputLeftElement,
  Divider,
} from "@chakra-ui/react";
import { addDoc, collection } from "firebase/firestore";
import { Context, db } from "./App";
import ConfettiExplosion from "react-confetti-explosion";
import { ECOMPANY } from "./constants";

const RegisterNewProjectModal: React.FC<{ data: any }> = ({ data }) => {
  const [phoneValue, setPhoneValue] = React.useState("");
  const [customerName, setCustomerName] = React.useState("");
  const [projectName, setProjectName] = React.useState("");
  const [isCreatingProject, setIsCreatingProject] = React.useState(false);
  const [isFinishedCreatingProject, setIsFinishedCreatingProject] =
    React.useState(false);
  const [newProjectId, setNewProjectId] = React.useState("");
  const [copySuccess, setCopySuccess] = React.useState(false);
  const [company] = useContext(Context);

  const addRecordToFirestore = async () => {
    try {
      // Remove undefined values from the data object
      const cleanData = JSON.parse(JSON.stringify(data));

      const docRef = await addDoc(collection(db, "projects"), {
        client_data: cleanData,
        client_name: customerName,
        project_name: projectName,
        phone: phoneValue,
        company: company,
      });

      console.log("Document written with ID: ", docRef.id);
      setNewProjectId(docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
      throw e;
    }
  };

  const handleCreateProject = async () => {
    setIsCreatingProject(true);
    await addRecordToFirestore();
    setIsCreatingProject(false);
    setIsFinishedCreatingProject(true);
  };
  return (
    <>
      <ChakraProvider>
        <Modal
          onClose={() => {}}
          isCentered
          isOpen={true}
          motionPreset="slideInBottom"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader textAlign="center">שמירת אומדן חדש</ModalHeader>
            <ModalBody>
              {isCreatingProject ? (
                <div>
                  <Stack direction="column" alignItems="center" gap={2}>
                    <Text>יוצר אומדן, יש להמתין...</Text>
                  </Stack>
                </div>
              ) : isFinishedCreatingProject ? (
                <Stack direction="column" alignItems="center" gap={2}>
                  <Stack direction="column" alignItems="center" gap={2}>
                    <ConfettiExplosion
                      zIndex={99999999}
                      particleCount={100}
                      width={500}
                    />
                  </Stack>
                  <Text fontSize="18px">האומדן נוצר בהצלחה</Text>
                  <Divider my={4} />
                  <FormLabel
                    textAlign="right"
                    width="100%"
                    htmlFor="projectIdInput"
                    pr="10px"
                  >
                    מזהה אומדן
                  </FormLabel>
                  <InputGroup size="lg">
                    <Input
                      pr={2.5}
                      value={newProjectId}
                      isReadOnly
                      resize="none"
                      placeholder="ID of the new project"
                    />
                    <InputLeftElement width="5.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={async () => {
                          await navigator.clipboard.writeText(newProjectId);
                          setCopySuccess(true);
                          setTimeout(() => {
                            setCopySuccess(false);
                          }, 2000);
                        }}
                      >
                        העתקה
                      </Button>
                    </InputLeftElement>
                  </InputGroup>
                  <FormLabel
                    textAlign="right"
                    width="100%"
                    htmlFor="projectIdInput"
                    pr="10px"
                  >
                    קישור לאומדן
                  </FormLabel>
                  <InputGroup size="lg">
                    <Input
                      pr={2.5}
                      pl={90}
                      value={`https://project.cuanto.co.il/?pid=${newProjectId}`}
                      isReadOnly
                      resize="none"
                      placeholder="ID of the new project"
                    />
                    <InputLeftElement width="5.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={async () => {
                          await navigator.clipboard.writeText(
                            `https://project.cuanto.co.il/?pid=${newProjectId}`
                          );
                          setCopySuccess(true);
                        }}
                      >
                        העתקה
                      </Button>
                    </InputLeftElement>
                  </InputGroup>
                  {copySuccess && (
                    <Text color="green.500" fontSize="sm">
                      העתקה בוצעה בהצלחה
                    </Text>
                  )}
                </Stack>
              ) : (
                <>
                  <FormLabel htmlFor="phoneInput">מספר טלפון</FormLabel>
                  <Input
                    id="phoneInput"
                    type="text"
                    mb={2}
                    placeholder="לדוגמא: 0508123456"
                    value={phoneValue}
                    onChange={(e) => setPhoneValue(e.target.value)}
                    onKeyDown={(e) => {
                      // if (e.key === "Enter") {
                      //   if (phoneValue === phoneNumber) {
                      //     handleSuccessfulAuth();
                      //   } else {
                      //     alert("מספר טלפון שגוי");
                      //   }
                      // }
                    }}
                  />
                  <FormLabel htmlFor="customerNameInput">שם לקוח מלא</FormLabel>
                  <Input
                    id="customerNameInput"
                    type="text"
                    mb={2}
                    placeholder="לדוגמא: ישראל ישראלי"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                    onKeyDown={(e) => {
                      // if (e.key === "Enter") {
                      //   if (phoneValue === phoneNumber) {
                      //     handleSuccessfulAuth();
                      //   } else {
                      //     alert("מספר טלפון שגוי");
                      //   }
                      // }
                    }}
                  />
                  <FormLabel htmlFor="projectNameInput">שם אומדן</FormLabel>
                  <Input
                    id="projectNameInput"
                    type="text"
                    mb={2}
                    placeholder="לדוגמא: אומדן בית חדש"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    onKeyDown={(e) => {
                      // if (e.key === "Enter") {
                      //   if (phoneValue === phoneNumber) {
                      //     handleSuccessfulAuth();
                      //   } else {
                      //     alert("מספר טלפון שגוי");
                      //   }
                      // }
                    }}
                  />
                  <Divider my={4} />
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    gap={2}
                  >
                    <Text display="flex" alignItems="center" gap={2}>
                      סוג פרויקט:{" "}
                      <img
                        style={{
                          display: "inline-block",
                        }}
                        src={
                          process.env.PUBLIC_URL +
                          `/icons/${
                            company === ECOMPANY.CUANTO ? "cuanto_logo" : "logo"
                          }.svg`
                        }
                        alt=""
                        width={100}
                      />
                    </Text>
                  </Stack>
                </>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                isDisabled={isCreatingProject || isFinishedCreatingProject}
                variant="solid"
                colorScheme="pink"
                onClick={handleCreateProject}
                isLoading={isCreatingProject}
              >
                יצירת אומדן
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </ChakraProvider>
    </>
  );
};

export default RegisterNewProjectModal;
