import { useEffect } from "react";

export interface ISection {
  name: string;
  entries: string[];
  cuanto?: string[];
  subEntries?: string[];
}

const SkeletonSection = (section: ISection) => {
  const sectionName = section.name;
  const isGeneralSection = sectionName.includes("הערות כלליות");
  const subEntries = section.subEntries;

  // console.table(section.entries);

  return (
    <div
      className={`no-page-break section ${
        isGeneralSection && "general-section"
      }`}
      data-section-name={section.name}
    >
      {!isGeneralSection && <h4 className="section-title">{section.name}</h4>}
      <ul className="section-text">
        {section.entries?.map((entry, index) => {
          let specialClass = "";
          if (entry.trim().includes("יציקות לפי תוכנית:")) {
            specialClass = "mid-entry";
          }
          return (
            <li key={index} className={specialClass}>
              {entry}
            </li>
          );
        })}
      </ul>
      {subEntries && (
        <div className="section general-section">
          <ul className="section-text">
            {subEntries.map((subEntry) => (
              <li>{subEntry}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SkeletonSection;
