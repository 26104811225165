import { chapters } from "./images_mapping";

export const TABLE_ROW_HEIGHT = 22;

export const getChapterProperties = (chapterName: string) => {
  // @ts-ignore
  return (
    chapters[chapterName] ||
    chapters[
      Object.keys(chapters).find((chapterKey) => chapterName === chapterKey)
    ]
  );
};

export const getEntriesByKey = (obj: any, key: string) => {
  return (
    obj[key] ||
    obj[
      Object.keys(obj).find(
        (objKey) => objKey.includes(key) || key.includes(objKey)
      )!
    ]
  );
};

export const formatComplexTableFromCSV = (csv: string) => {
  const lines: string[] = csv.split("\n");
  return convertArray(
    lines
      .filter((line) => {
        return !line.includes("₪ -");
      })
      .map((line) => {
        const innerLine: string[] = [];
        let insideQuotes = false;
        let field = "";

        for (let char of line) {
          if (char === '"') {
            insideQuotes = !insideQuotes;
          } else if (char === "," && !insideQuotes) {
            innerLine.push(field.trim());
            field = "";
          } else {
            field += char;
          }
        }

        innerLine.push(field.trim());

        return innerLine.filter((innerLineItem) => innerLineItem !== "");
      })
  );
};

function convertArray(inputArray) {
  const result: any = [];
  let currentCategory: any = "";
  let currentItems: any = [];

  inputArray.forEach((item) => {
    // If the item is a category
    if (item.length === 1) {
      // If there are items in the current category, add them to the result
      if (currentItems.length > 0) {
        result.push({ [currentCategory]: { values: currentItems } });
        currentItems = [];
      }
      // Set the current category
      currentCategory = item[0];
    } else {
      // Add item to current items
      currentItems.push(item);
    }
  });

  // Add the last category if it has items
  if (currentItems.length > 0) {
    result.push({ [currentCategory]: { values: currentItems } });
  }

  result.map((innerArr, i) => {
    const key = Object.keys(innerArr)[0];
    const catHeight = TABLE_ROW_HEIGHT * innerArr[key].values.length;
    innerArr[key].height = catHeight;
    innerArr[key].title = key;
    result.push(innerArr[key]);
    delete result[i];
  });

  return result;
}

export function mergeAndTrimChapter(jsonArray) {
  // Create an object to store merged entries by name
  let mergedObj = {};

  // Iterate over each object in the array
  for (let obj of jsonArray) {
    // Trim the leading and trailing whitespace of the 'name' property
    let trimmedName = obj.name.trim();

    // If an object with the same name already exists in mergedObj, merge their entries
    if (mergedObj.hasOwnProperty(trimmedName)) {
      mergedObj[trimmedName].entries.push(...obj.entries);
    } else {
      // Otherwise, create a new entry in mergedObj
      mergedObj[trimmedName] = {
        name: trimmedName,
        entries: obj.entries,
      };
    }
  }

  // Convert the values of mergedObj back to an array
  let mergedArray = Object.values(mergedObj);
  return mergedArray;
}

export function getColumnCountFromCsv(csv) {
  return csv && csv?.split("\n")[0].split(",").length;
}

export function transformSheetPrices(htmlSheet, { removeDecimals }) {
  if (removeDecimals) {
    // Define the regular expression pattern
    let pattern = /₪(\d+(?:,\d{3})*)\.(\d{2})/g;

    // Replace decimal points and print modified text
    let modifiedText = htmlSheet.replace(pattern, "₪$1");
    return modifiedText;
  }
  return htmlSheet;
}
