import Chapter, { IChapter } from "./pdf_components/Chapter";
import React, { createElement, useContext, useRef } from "react";
import { getChapterProperties, mergeAndTrimChapter } from "./assets/utils";
import { ECOMPANY } from "./constants";
import Drawer from "@mui/material/Drawer";
import {
  ListItemButton,
  ListItemText,
  List,
  Stack,
  Divider,
  Box,
} from "@mui/material";
import { Context } from "./App";
import { isMobile } from "react-device-detect";

export const CHAPTER_HEIGHT = 1150;
export const OVERFLOW_BUFFER = 200;

const WebPreview = ({
  chapters,
  company,
  viewType = "web",
}: {
  chapters: IChapter[];
  company: ECOMPANY;
  viewType?: "web" | "print";
}) => {
  // set company in context
  const [, setCompany] = useContext(Context);
  console.log({ company });
  setCompany(company);

  const pdfRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Stack
        width="100%"
        justifyContent="center"
        alignItems="center"
        my={4}
        className="hide-on-print"
      ></Stack>

      {!isMobile && (
        <Drawer
          open={true}
          anchor="right"
          variant="persistent"
          PaperProps={{
            style: {
              width: "250px",
              padding: "1rem",
              paddingTop: "0",
              overflow: "auto",
              backgroundColor: "white",
              color: "black",
            },
          }}
        >
          <h1>פרקים</h1>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
            {chapters.map((chapter) => {
              return (
                <>
                  <ListItemButton
                    onClick={() => {
                      const el = pdfRef.current?.querySelector(
                        `[data-name="${chapter.name}"]`
                      );
                      if (el) {
                        el.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                        });
                      }
                    }}
                  >
                    <ListItemText
                      primary={chapter.name}
                      sx={{
                        textAlign: "right",
                      }}
                    />
                  </ListItemButton>
                </>
              );
            })}
          </List>
        </Drawer>
      )}
      <div
        className={`web-preview web ${isMobile ? "mobile" : ""}`}
        ref={pdfRef}
      >
        {chapters.map((chapter) => {
          chapter.sections = mergeAndTrimChapter(chapter.sections) as any;
          const chapterProperties = getChapterProperties(chapter?.name);
          chapter.title = chapterProperties?.title || chapter.name;
          chapter.group = chapterProperties?.group || 0;
          chapter.breakSections = !!chapterProperties?.breakSections;
          chapter.noSections = !!chapterProperties?.noSections;
          chapter.hideInCuanto = !!chapterProperties?.hideInCuanto;

          // console.table({ chapter });

          if (
            (chapter.sections.length === 0 && !chapter.noSections) ||
            (chapter.hideInCuanto && company === ECOMPANY.CUANTO)
          )
            return false;

          const ChapterComponent = chapterProperties?.component || Chapter;

          return (
            <React.Fragment key={chapter.name}>
              <ChapterComponent {...chapter} />
              <Box my={12}>
                <Divider
                  sx={{
                    maxWidth: "793px",
                  }}
                />
              </Box>
            </React.Fragment>
          );
        })}
      </div>
      {/* <div
        className={`web-preview ${viewType !== "web" ? "hidden" : ""}`}
        ref={pdfRef}
      >
        {chapters.map((chapter) => {
          chapter.sections = mergeAndTrimChapter(chapter.sections) as any;
          const chapterProperties = getChapterProperties(chapter?.name);
          chapter.title = chapterProperties?.title || chapter.name;
          chapter.group = chapterProperties?.group || 0;
          chapter.breakSections = !!chapterProperties?.breakSections;
          chapter.noSections = !!chapterProperties?.noSections;
          chapter.hideInCuanto = !!chapterProperties?.hideInCuanto;
          if (
            (chapter.sections.length === 0 && !chapter.noSections) ||
            (chapter.hideInCuanto && company === ECOMPANY.CUANTO)
          )
            return false;
          return chapterProperties?.component
            ? createElement(chapterProperties.component, chapter)
            : Chapter(chapter);
        })}
      </div> */}
    </>
  );
};

export default WebPreview;
