import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ChakraProvider,
} from "@chakra-ui/react";

const ErrorModal = ({ error }: { error: string }) => {
  return (
    <ChakraProvider>
      <Modal
        isCentered
        onClose={() => {}}
        isOpen={true}
        motionPreset="slideInBottom"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center">אירעה שגיאה</ModalHeader>
          <ModalBody textAlign="center">{error}</ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default ErrorModal;
