import React, { createContext, useEffect, useRef, useState } from "react";
import "./App.scss";
import * as XLSX from "xlsx";
import PdfPreview from "./PdfPreview";
import { FileDrop } from "react-file-drop";
import { ECOMPANY, firebaseConfig } from "./constants";
import { ErrorBoundary } from "react-error-boundary";
import Error from "./pdf_components/Error";
import { initializeApp } from "firebase/app";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import WebPreview from "./WebPreview";
import { Stack, Typography } from "@mui/material";
import Login from "./Login";
import ErrorModal from "./ErrorModal";
import { IChapter } from "./pdf_components/WorkDetails";
import { isMobile } from "react-device-detect";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const db = getFirestore(firebaseApp);

export const Context = createContext<any>([]);

interface ClientLoginResponse {
  client_data: IChapter[];
  tz?: string;
  phone?: string;
  company?: ECOMPANY;
}

enum EACTION {
  UPLOAD = "upload",
}

function App() {
  const [workbook, setWorkbook] = React.useState<XLSX.WorkBook>();
  const [isDraggedOver, setIsDraggedOver] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [company, setCompany] = useState(ECOMPANY.ROSHENROZ);
  const [clientData, setClientData] =
    useState<ClientLoginResponse | null>(null);
  const [hideGraphs, setHideGraphs] = useState(false);
  const [finishedLoadingRecord, setFinishedLoadingRecord] = useState(false);
  const [failedToLoadProject, setFailedToLoadProject] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loginSuccess, setLoginSuccess] = useState(false);

  // get query param p from url
  const urlParams = new URLSearchParams(window.location.search);
  const pid = urlParams.get("pid");
  const action = urlParams.get("action");

  useEffect(() => {
    // console.log("pid", pid);
    // Function to fetch a specific document in the "projects" collection
    async function getProjectById(pid: string) {
      try {
        const docRef = doc(db, "projects", pid); // Reference to the document with the specific ID
        const docSnap = await getDoc(docRef); // Get the document

        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data()); // Log the document data
          setFinishedLoadingRecord(true);
          setClientData(docSnap.data() as ClientLoginResponse);
        } else {
          console.log("No such document!");
          setFinishedLoadingRecord(true);
          setFailedToLoadProject(true);
        }
      } catch (error) {
        console.error("Error fetching project by ID:", error);
        setFailedToLoadProject(true);
      }
    }

    if (pid) {
      getProjectById(pid);
    }
  }, [pid]);

  useEffect(() => {
    setTimeout(() => {
      const chapters = document.getElementsByClassName("chapter");
      document.body.setAttribute("data-company", company);
      // @ts-ignore
      for (let chapter of chapters) {
        if (chapter.clientHeight > 1123) {
          const imageHeight =
            chapter.getElementsByClassName("chapter-image")?.[0]?.clientHeight;
          if (chapter.clientHeight - imageHeight <= 1123) {
            chapter.getElementsByClassName(
              "chapter-image"
            )[0].children[0].style.display = "none";
          }
        }
      }
    }, 1000);
  }, [company]);
  const onFileLoaded = (readerResult: any) => {
    const excelFile = XLSX.read(readerResult, { type: "binary" });
    setWorkbook(excelFile);
  };

  const loadFile = (file: File) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    setIsUploading(true);
    reader.onload = () => onFileLoaded(reader.result);
  };

  const onFileInputChange = (event) => {
    const { files } = event.target;
    loadFile(files[0]);
  };

  const onTargetClick = () => {
    fileInputRef?.current?.click();
  };

  if (pid && isMobile) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        zIndex="1000"
        bgcolor="rgba(0, 0, 0, 0.75)"
      >
        <Typography
          style={{ color: "white" }}
          textAlign="center"
          fontSize={20}
          fontWeight="bold"
        >
          לקוח יקר,
          <br />
          בשלב זה אפליקצית Cuanto
          <br />
          נתמכת במחשבים נייחים וניידים בלבד
        </Typography>
      </Stack>
    );
  }

  if (pid && !finishedLoadingRecord) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        zIndex="1000"
        bgcolor="rgba(0, 0, 0, 0.5)"
      >
        <div role="status">
          <svg
            aria-hidden="true"
            className="inline w-20 h-20 text-gray-200 animate-spin dark:text-white fill-gray-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </div>
        <span className="text-white text-2xl">טוען אומדן...</span>
      </Stack>
    );
  }

  if (failedToLoadProject) {
    return <ErrorModal error="שגיאה בטעינת המסמך" />;
  }

  if (pid && !loginSuccess && clientData) {
    return (
      <Login
        phoneNumber={clientData?.phone || "0"}
        handleSuccessfulAuth={() => {
          setFinishedLoadingRecord(true);
          setLoginSuccess(true);
        }}
      />
    );
  }

  if (pid && finishedLoadingRecord && loginSuccess) {
    return (
      <ErrorBoundary FallbackComponent={Error}>
        <Context.Provider
          value={[company, setCompany, hideGraphs, setHideGraphs]}
        >
          <WebPreview
            chapters={clientData?.client_data || []}
            company={clientData?.company || ECOMPANY.CUANTO}
          />
        </Context.Provider>
      </ErrorBoundary>
    );
  }

  if (workbook) {
    return (
      <ErrorBoundary FallbackComponent={Error}>
        <Context.Provider
          value={[company, setCompany, hideGraphs, setHideGraphs]}
        >
          <PdfPreview
            Sheets={workbook.Sheets}
            SheetNames={workbook.SheetNames}
          />
        </Context.Provider>
      </ErrorBoundary>
    );
  } else if (action === EACTION.UPLOAD) {
    return (
      <Context.Provider
        value={[company, setCompany, hideGraphs, setHideGraphs]}
      >
        <div
          className={`App ${company === ECOMPANY.CUANTO ? "cuanto" : ""}`}
          style={{
            background: `url('${
              process.env.PUBLIC_URL + "/images/bg_rosh.jpg"
            }')`,
            backgroundSize: "cover",
          }}
        >
          {isUploading && (
            <div className="bg-black/90 flex w-full h-full absolute justify-center items-center z-50">
              <span className="text-white text-5xl">
                טוען אומדן
                <div role="status">
                  <svg
                    aria-hidden="true"
                    className="w-16 h-16 text-gray-200 animate-spin dark:text-gray-600 fill-white mt-4"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              </span>
            </div>
          )}
          <div>
            <img
              src={
                process.env.PUBLIC_URL +
                `/icons/${
                  company === ECOMPANY.CUANTO ? "cuanto_logo" : "logo"
                }.svg`
              }
              className="w-28 opacity-100"
              alt=""
            />
            <h1 className="text-6xl mb-0 mt-0">ExcelEstate</h1>
            <h4 className="p-0 mb-10 mt-2">
              Previously (Silicone) Guns & Rozes
              <br />
              <span style={{ fontSize: 14, fontWeight: "normal" }}>
                Version {process.env.REACT_APP_ROSH_VERSION}
              </span>
            </h4>

            <div>
              <input
                onChange={onFileInputChange}
                ref={fileInputRef}
                type="file"
                className="hidden"
              />
              <select
                id="company"
                className="mb-2 border border-gray-500 border-dashed text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                onChange={(e) => {
                  setCompany(e.target.value as ECOMPANY);
                }}
              >
                <option selected value={ECOMPANY.ROSHENROZ}>
                  Roshenroz
                </option>
                <option value={ECOMPANY.CUANTO}>Cuanto</option>
              </select>
              <div className="flex my-2 mb-5 flex-row-reverse">
                <div className="flex items-center h-5">
                  <input
                    id="helper-checkbox"
                    aria-describedby="helper-checkbox-text"
                    type="checkbox"
                    value=""
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    onClick={() => setHideGraphs(!hideGraphs)}
                  />
                </div>
                <div className="ms-2 text-sm">
                  <label
                    htmlFor="helper-checkbox"
                    className="font-medium text-gray-900"
                  >
                    הסתרת גרפים
                  </label>
                </div>
              </div>
              <FileDrop
                className={
                  isDraggedOver ? "file-drop dragged-over" : "file-drop"
                }
                onTargetClick={onTargetClick}
                onFrameDragEnter={(event) => {}}
                onFrameDragLeave={(event) => {
                  setIsDraggedOver(false);
                }}
                onFrameDrop={(event) => {
                  setIsDraggedOver(false);
                }}
                onDragOver={(event) => {
                  setIsDraggedOver(true);
                }}
                onDragLeave={(event) => console.log("onDragLeave", event)}
                onDrop={(files, event) => {
                  if (files) {
                    loadFile(files[0]);
                  }
                }}
              >
                גרור / גררי קובץ לכאן או לחץ / לחצי כדי להעלות קובץ
                <br />
                <span dir="rtl">
                  קבצים נתמכים: <code>.xls,.xlsx,.xlsm,.xlsb</code>
                </span>
              </FileDrop>
            </div>
            <div className="flex justify-center items-center flex-col mt-8">
              <span>Powered By:</span>
              <a
                href="https://www.linkedin.com/in/nir-tzezana-918a376b/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/tz_logo.png"}
                  className="w-28 opacity-80"
                  alt=""
                />
              </a>
            </div>
          </div>
          {/* <header className="App-header">
            <h2>(Silicone) Guns & RoZes</h2>
            <img src={logo} className="App-logo" alt="logo" />
            <input
              type="file"
              onChange={(e) => {
                if (e.target && e.target.files) {
                  loadFile(e.target.files[0]);
                }
              }}
              accept=".xls,.xlsx,.xlsm,.xlsb"
            />
            <h5>Version 0.1.7</h5>
          </header> */}
        </div>
      </Context.Provider>
    );
  } else {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
      >
        <img
          src={process.env.PUBLIC_URL + `/icons/cuanto_logo.svg`}
          alt=""
          className="w-28 opacity-100"
        />
        <div className="hidden">
          {
            // redirect
            (window.location.href = "https://www.cuanto.co.il")
          }
        </div>
      </Stack>
    );
  }
}

export default App;
